import React from "react";
import {
  FiActivity,
  FiCast,
  FiFastForward,
  FiHexagon,
  FiLock,
  FiStar,
  FiMap,
  FiNavigation,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: <FiLock />,
    title: "Reliable",
    description:
      "Your hotel guests can quickly scan QR codes displayed in their rooms that feature your logo and branding.",
  },
  {
    icon: <FiStar />,

    title: "Simple",
    description:
      "Guests can swiftly access the menu in numerous languages with no need for app installations or data exchange.",
  },

  {
    icon: <FiNavigation />,
    title: "Fast",
    description:
      "Orders are arrived within minutes, providing guests with over 300 options in a single basket for maximum comfort.",
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon serviceIcons">{val.icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;

import React from "react";
import footerOne from "../../data/footer/footerOne.json";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];
const footerIntemSix = footerOne[6];

const Copyright = () => {
	return (
		<div className="copyright-area copyright-style-one">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-8 col-sm-12 col-12">
						<div className="copyright-left">
							{/* <ul className="ft-menu link-hover">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms And Condition</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul> */}
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="rn-footer-widget">
									<h4 className="title">{footerIntemFive.title}</h4>
									<div className="inner">
										<h6 className="subtitle">{footerIntemFive.subtitle}</h6>
										<ul className="social-icon social-default justify-content-start">
											<li>
												<a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/papyonsolutions/">
													<FiInstagram />
												</a>
											</li>
											<li>
												<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/papyon-room-service-solutions/">
													<FiLinkedin />
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-4 col-sm-12 col-12">
						<div className="copyright-right text-center text-md-end">
							<p className="copyright-text">© Papyon {new Date().getFullYear()}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Copyright;

import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import ServiceOne from "../elements/service/ServiceOne";
import SlipThree from "../elements/split/SlipThree";
import AdvanceTabOne from "../elements/advancetab/AdvanceTabOne";
import ServiceFour from "../elements/service/ServiceFour";
import ContactForm from "../elements/contact/ContactForm";
import VideoItem from "../elements/video/VideoItem";

var BlogListData = BlogClassicData.slice(0, 3);

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: ["https://www.youtube.com/watch?v=95xBxbTJFuk"],
  },
];

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Papyon Solutions" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">
                    Papyon Room Service Solutions
                  </span>
                  <h3 className="title hColor display-two">
                    New Standards in <br />{" "}
                    <h3 className="title roomColor display-two">
                      Room Service
                    </h3>{" "}
                    {"       "}
                    <br />
                    {/* <div className="tyPedColor" > */}
                    <Typed
                      // style={{ color: "#ff5252 !important" }}
                      className="typedColor"
                      strings={["Innovation", "Excellence", "Profitability"]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h3>
                  <p className="description">
                    Revolutionising in Hospitality Sector: Redefining Guest
                    Experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* <Separator /> */}

        {/* Start About Area  */}
        {/* <AboutOne /> */}
        {/* End About Area  */}

        {/* <Separator /> */}
        <div className="rwt-video-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              {PopupData.map((item) => (
                <div className="col-lg-12" key={item.id}>
                  <VideoItem galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container" id="about">
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        <Separator />

        <SlipThree />

        <div className="rwt-advance-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="You can acquire all."
                  title={"Papyon’s Global Footprint: Redefining Hospitality"}
                  description="Papyon has been on a remarkable growth journey since 2021. It now provides its services across two countries and five cities and is still expanding at a fast pace."
                />
              </div>
            </div>
            <AdvanceTabOne />
          </div>
        </div>

        <Mission />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="What we can do for you"
                  title="Exploring Papyon's Progress"
                  description=""
                />
              </div>
            </div>
            <ServiceFour
              serviceStyle="service__style--1 icon-circle-style with-working-process"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        {/* Start Mission Area  */}

        <Separator />
        {/* Start Testimonial Area  */}

        {/* End Testimonial Area  */}

        <Separator />
        <div className="blog-area ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="Latests News"
                  title="Papyon in the Press"
                  description="Explosive Growth, Captivating Service"
                />
              </div>
            </div>
            <div className="row row--15">
              {BlogListData.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                  <BlogList StyleVar="box-card-style-default" data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div id="contact" className="contact-area rn-section-gapBottom pt-5">
          <div className="container pt-5">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="Latests News"
                  title="Contact us today to schedule a Demo Day"
                  description="Papyon: Swift Action, Every Occasion"
                />
              </div>
            </div>
            <div className="row row--30 mt--40">
              <div className="col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30">
                <ContactForm formStyle="contact-form-1" />
              </div>
              <div className="col-lg-6 order-1 oder-lg-2">
                <div className="thumbnail">
                  <img
                    className="radius w-100"
                    src="./images/about/contact-image.png"
                    alt="Corporate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;

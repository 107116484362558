import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
  {
    countNum: 1000,
    countTitle: "Hotels",
  },
  {
    countNum: 28100,
    countTitle: "Rooms",
  },
];

const SlipThree = () => {
  return (
    <div className="rn-splite-style bg-color-blackest">
      <div className="split-wrapper bg-white">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail">
              <img src="./images/split/split-03.png" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">About Papyon</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Papyon Room Service Solutions is revolutionising the way room
                  service operates in the hospitality industry. With a focus on
                  efficiency, reliability, and simplicity, Papyon ensures that
                  the needs of every guest in their partner hotels are met
                  promptly and effectively. Tailored to support a variety of
                  rentable accommodations, including hotels, hostels, and
                  apartments, Papyon empowers its affiliates to provide an
                  outstanding room service experience. From the very first day,
                  Papyon has committed to enhancing guest satisfaction while
                  driving profitability for its partners.
                </p>
              </ScrollAnimation>

              <div className="row">
                {Data.map((data, index) => (
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={index}>
                    <div className="count-box counter-style-4 text-start">
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <div className="count-number">
                              {isVisible ? <CountUp end={data.countNum} /> : 0}
                            </div>
                          )
                        }
                      </TrackVisibility>
                      <h5 className="counter-title">{data.countTitle}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlipThree;

import axios from "axios";
import configs from "../config";

const { API_URL } = configs.data;
const baseApi = axios.create({
	baseURL: API_URL,
});
// ----------------------------------------

const api = {
	postContactUs: (body) => {
		return baseApi.post(`${API_URL}/v1/contact-us`, body).then((res) => res.data);
	},
	// ----------------------------------------
	get: () => baseApi.get(`${API_URL}`).then((res) => res.data),
	// -------------------------------------------------------------------------------- POST
};
export default api;

import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header className="bg-papyon">
          <Accordion.Toggle
            className="faqText"
            as={Button}
            variant="link"
            eventKey="0"
          >
            How does Papyon tramsform the hospitality sector?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              As food and staffing expenses soar, maintaining round-the-clock
              kitchen and room services for hotels and tourism enterprises has
              become a daunting and expensive task.
            </p>
            <p>
              Since launching in January 2021, Papyon has been a game-changer in
              the hospitality sector by eliminating the costs associated with
              kitchen and room services for tourism-related businesses. We offer
              a premium room service solution that not only meets all guest
              requirements but also ensures that businesses profit from every
              order placed.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header className="bg-papyon">
          <Accordion.Toggle
            className="faqText"
            as={Button}
            variant="link"
            eventKey="1"
          >
            How does Papyon enhance the room service experience for hotel
            guests?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              Hotel guests simply scan QR codes uniquely assigned to their hotel
              and room, conveniently placed in their rooms by our team. They
              access Papyon's expansive digital menu, featuring an array of
              categories, including Food, Beverages, Personal Care and
              Cosmetics, Technology, Rental Products, Snacks, and Minibar, from
              which they can select more than 300 products.
            </p>
            <p>
              Papyon then processes these orders, coordinating with vendors and
              food suppliers to deliver them to your guests' rooms through our
              dedicated delivery team. With our robust and efficient operations
              team, we seamlessly handle the entire process, from order
              placement to post-order satisfaction, without burdening you with
              any additional workload.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header className="bg-papyon">
          <Accordion.Toggle
            className="faqText"
            as={Button}
            variant="link"
            eventKey="2"
          >
            Interested in Partnering with Papyon?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse className="faqText" eventKey="2">
          <Card.Body>
            Just fill out the contact form on our website to get started. Our
            committed sales team will be in touch with you shortly.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;

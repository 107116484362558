import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import { useMutation } from "react-query";
import api from "../../api";

const Result = () => {
	return <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>;
};

function ContactForm({ props, formStyle }) {
	const [result, showresult] = useState(false);

	const form = useRef();
	const onSuccessMutating = () => {
		form.current.reset();
		showresult(true);
		setTimeout(() => {
			showresult(false);
		}, 5000);
	};

	const { mutate, isLoading } = useMutation("contact", (body) => api.postContactUs(body), {
		onSuccess: onSuccessMutating,
		onError: (error) => {
			console.log({ error });
		},
	});

	const sendEmail = (e) => {
		e.preventDefault();

		// emailjs.sendForm("service_94abotj", "template_3x5xlzl", e.target, "user_jrfTH2e0Ely35ZCVFdT9S").then(
		// emailjs.sendForm("service_94abotj", "template_3x5xlzl", e.target, "6Vx0pVSSnTAEaTs78").then(
		// 	(result) => {
		// 		console.log({ result: result.text });
		// 	},
		// 	(error) => {
		// 		console.log({ error: error.text });
		// 	}
		// );
		const formData = new FormData(e.target);
		const reqData = {
			name: formData.get("fullname"),
			email: formData.get("email"),
			phone: formData.get("phone"),
			subject: formData.get("subject"),
			text: formData.get("message"),
		};
		console.log({ reqData });
		mutate(reqData);
		// e.target.reset();
	};

	// useEffect(()=>{

	// },[])

	return (
		<form ref={form} className={`${formStyle}`} action="" onSubmit={sendEmail}>
			<div className="form-group">
				<input type="text" name="fullname" placeholder="Your Name" required />
			</div>

			<div className="form-group">
				<input type="email" name="email" placeholder="Email Address" required />
			</div>

			<div className="form-group">
				<input type="text" name="phone" placeholder="Phone Number" required />
			</div>

			<div className="form-group">
				<input type="text" name="subject" placeholder="Subject" required />
			</div>

			<div className="form-group">
				<textarea name="message" placeholder="Your Message" required></textarea>
			</div>

			<div className="form-group">
				<button className="contactButton" disabled={isLoading}>
					{isLoading ? "Loading" : "Submit Now"}
				</button>
			</div>

			<div className="form-group">{result ? <Result /> : null}</div>
		</form>
	);
}
export default ContactForm;

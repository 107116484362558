import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from "../accordion/AccordionOne";

const mission = () => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title align-items-center text-center">
              <h2 className="title">
                Discover Papyon Today! <br />{" "}
              </h2>
              {/* <p>
                For a deeper understanding of what we offer, reach out to us
                now.
              </p> */}
              <img
                className="small-radius"
                src="./images/tab/2.png"
                alt="Corporate React Template"
              />
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne customStyle="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;

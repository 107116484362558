// export const API_URL = "https://api-dev.papyonsolutions.com/v1/";
// export const API_URL_V2 = "https://api-dev.papyonsolutions.com/v2/";
// // export const API_URL = "https://api.papyonsolutions.com/v1/";
// // export const API_URL_V2 = "https://api.papyonsolutions.com/v2/";
// export const SOCKET_URL = "wss://188.166.96.29:2083/panel";


import production from "./production.js";
import develop from "./development.js";

let isProduction = process.env.REACT_APP_NODE_ENV == "production";
let isDevelop = process.env.REACT_APP_NODE_ENV == "development";

const configs = {
    isProduction,
    isDevelop,
    data: isDevelop ? develop : production
}
console.log({ configs });

export default configs